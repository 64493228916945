



























































































































































































































































































































































































































































































































































































.promoteBox {
  .terminalsSet {
    /deep/.el-cascader {
      width: 215px;
      .el-cascader__tags {
        .el-tag {
          span {
            min-width: 1rem !important;
          }
        }
      }
    }
  }
}
